import { Action } from '@ngrx/store';
import { OptionType } from '@touch-apps/options-model';

export enum OptionsActionTypes {
  LOAD_OPTIONS = '[Option] Load Options',
  LOAD_OPTIONS_SUCCESS = '[Option] Load Options success',
  LOAD_OPTIONS_FAILURE = '[Option] Load Options failure',
  SET_OPTIONS = '[Option] Set Options',

  SET_OPTION_ID_BEING_EDITED = '[Option] Set option id being edited',
  CREATE_OPTION = '[Option] Create option',
  CREATE_OPTION_SUCCESS = '[Option] Create option success',

  DELETE_OPTION = '[Option] Delete option',
  DELETE_OPTION_SUCCESS = '[Option] Delete option success',

  SAVE_OPTION = '[Option] Save option being edited',
  SAVE_OPTION_SUCCESS = '[Option] Save option success',
  SAVE_OPTION_FAILURE = '[Option] Save option failure',
}

export class LoadOptions implements Action {
  readonly type = OptionsActionTypes.LOAD_OPTIONS;
}

export class SetOptions implements Action {
  readonly type = OptionsActionTypes.SET_OPTIONS;

  constructor(public payload: OptionType[]) {}
}

export class LoadOptionsSuccess implements Action {
  readonly type = OptionsActionTypes.LOAD_OPTIONS_SUCCESS;
  constructor(public options: any) {}
}

export class LoadOptionsFailure implements Action {
  readonly type = OptionsActionTypes.LOAD_OPTIONS_FAILURE;
  constructor(public error: any) {}
}

export class SetOptionIdBeingEdited implements Action {
  readonly type = OptionsActionTypes.SET_OPTION_ID_BEING_EDITED;

  constructor(public optionId: string) {}
}

export class CreateOption implements Action {
  readonly type = OptionsActionTypes.CREATE_OPTION;

  constructor(public optionName: string, public optionDescription: string) {}
}

export class CreateOptionSuccess implements Action {
  readonly type = OptionsActionTypes.CREATE_OPTION_SUCCESS;

  constructor(public option: OptionType) {}
}

export class SaveOption implements Action {
  readonly type = OptionsActionTypes.SAVE_OPTION;

  constructor() {}
}

export class SaveOptionSuccess implements Action {
  readonly type = OptionsActionTypes.SAVE_OPTION_SUCCESS;

  constructor(public option: OptionType) {}
}

export class SaveOptionFailure implements Action {
  readonly type = OptionsActionTypes.SAVE_OPTION_FAILURE;
  constructor(public error: any) {}
}

export class DeleteOption implements Action {
  readonly type = OptionsActionTypes.DELETE_OPTION;
  constructor(public optionId: string) {}
}

export class DeleteOptionSuccess implements Action {
  readonly type = OptionsActionTypes.DELETE_OPTION_SUCCESS;
  constructor(public optionId: string) {}
}
export type OptionsActions =
  | LoadOptions
  | LoadOptionsSuccess
  | LoadOptionsFailure
  | SetOptions
  | SetOptionIdBeingEdited
  | CreateOption
  | CreateOptionSuccess
  | DeleteOption
  | DeleteOptionSuccess
  | SaveOption
  | SaveOptionSuccess
  | SaveOptionFailure;
