import { Action } from '@ngrx/store';
import { MenuItem } from '../navigation.model';

export enum  NavigationActionTypes {
  SET_HEADER = '[Navigation] Set Header',
  CLEAR_HEADER = '[Navigation] Clear Header',

  SET_SIDEMENU = '[Navigation] Set Side Menu',
  CLEAR_SIDEMENU = '[Navigation] Clear Side Menu',

  SET_ADMIN_MENUS = '[Navigation] Set Admin Menus',
  SET_LEAD_MENUS = '[Navigation] Set Lead Menus',
  SET_USER_MENUS = '[Navigation] Set User Menus',

  CLEAR_MENUS = '[Navigation] Clear Menus',

  SET_FOOTER_VIEW = '[Navigation] Set footer view'
}


export class SetHeader implements Action {
  readonly type = NavigationActionTypes.SET_HEADER;

  constructor(public payload: MenuItem[]) {
  }
}

export class ClearHeader implements Action {
  readonly type = NavigationActionTypes.CLEAR_HEADER;
}

export class SetSideMenu implements Action {
  readonly type = NavigationActionTypes.SET_SIDEMENU;

  constructor(public payload: MenuItem[]) {
  }
}

export class ClearSideMenu implements Action {
  readonly type = NavigationActionTypes.CLEAR_SIDEMENU;
}

export class SetAdminMenus implements Action {
  readonly type = NavigationActionTypes.SET_ADMIN_MENUS;
}

export class SetLeadMenus implements Action {
  readonly type = NavigationActionTypes.SET_LEAD_MENUS;
}

export class SetUserMenus implements Action {
  readonly type = NavigationActionTypes.SET_USER_MENUS;
}

export class ClearMenus implements Action {
  readonly type = NavigationActionTypes.CLEAR_MENUS;
}

export class SetFooterView implements Action {
  readonly type = NavigationActionTypes.SET_FOOTER_VIEW;
  constructor(public canView: boolean) {
  }
}

export type NavigationActions =
  SetHeader
  | ClearHeader
  | SetSideMenu
  | ClearSideMenu
  | SetAdminMenus
  | SetLeadMenus
  | SetUserMenus
  | ClearMenus
  | SetFooterView;
