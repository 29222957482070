import { OptionsActions, OptionsActionTypes } from './options.actions';

import { CallingState } from '../../state/app.state';
import { OptionType } from '@touch-apps/options-model';

export interface OptionsState {
  options: OptionType[];
  currentOptionIdBeingEdited: string;
  callState: CallingState;
  optionIdCreated: string;
}

const initialState: OptionsState = {
  options: [],
  currentOptionIdBeingEdited: '',
  callState: CallingState.INIT,
  optionIdCreated: ''
};

export function optionsReducer(state = initialState, action: OptionsActions): OptionsState {
  switch (action.type) {
    case OptionsActionTypes.LOAD_OPTIONS:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case OptionsActionTypes.LOAD_OPTIONS_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        options: action.options
      };
    case OptionsActionTypes.LOAD_OPTIONS_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED
      }

    case OptionsActionTypes.SET_OPTIONS:
      return {
        ...state,
        options: action.payload
      };
    case OptionsActionTypes.CREATE_OPTION:
      return {
        ...state,
        optionIdCreated: ''
      }
    case OptionsActionTypes.SET_OPTION_ID_BEING_EDITED:
      return {
        ...state,
        currentOptionIdBeingEdited: action.optionId
      }
    case OptionsActionTypes.CREATE_OPTION_SUCCESS:
      return {
        ...state,
        optionIdCreated: action.option.optionId,
        options: AddOption(state.options, action.option)
      }
    case OptionsActionTypes.DELETE_OPTION:
      return {
        ...state
      }
    case OptionsActionTypes.SAVE_OPTION:
      return {
        ...state,
        callState: CallingState.CALLING
      }
    case OptionsActionTypes.SAVE_OPTION_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      }
    case OptionsActionTypes.SAVE_OPTION_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED
      }
    default: {
      return state;
    }
  }
}

const AddOption = (options: OptionType[], option: OptionType): OptionType [] => {
  const updatedOptions = [...options];
  updatedOptions.push(option);
  return updatedOptions;
}




